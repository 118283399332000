@media (max-width: 768px) {
  #center_column .pb-left-column .image-block {
    margin-top: 10px;
  }
}
.ribbon-white {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.ribbon-white:after {
  float: left;
  border-width: 0 !important;
  border: 10px solid #F4A700;
  border-right-color: #FFFFFF !important;
  margin: 5px 0px 3px 0px;
  content: "" !important;
}
.page-product-heading {
  margin: 0 0 20px;
  position: relative;
  background: #fbfbfb;
  font-size: 18px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 600;
  border: 0;
  line-height: 20px;
  padding: 5px 20px;
}
.info-product .pb-left-column img {
  max-width: 100%;
  height: auto
}
.info-product .pb-left-column .image-block {
  position: relative;
  display: block;
  cursor: pointer;
  background: #fff
}
@media(max-width:768px) {
  .info-product .pb-left-column .image-block {
    margin: 0 auto;
  }
}
.info-product .pb-left-column .image-block .view_full_size {
  display: block;
  margin: 0px auto 0px auto;
  max-width: 558px;
  width: 100%;
}
.info-product .pb-left-column .image-block img {
  background: #fbfbfb;
  width: 100%;
}
@media(min-width:1200px) {
  .info-product .pb-left-column .image-block .view_full_size {
    width: 558px;
	height: 558px;
    max-width: 100%;
  }
  .info-product .pb-left-column .image-block img {
    width: 558px;
	height: 558px;
    max-width: 100%;
  }
}

.info-product .pb-left-column .image-block .view_full_size .span_link {
  position: absolute;
  bottom: 60px;
  left: 50%;
  display: block;
  text-align: center;
  line-height: 22px;
  text-align: center;
  font-weight: 600;
}
.info-product .pb-left-column .image-block .view_full_size .span_link:after {
  font-family: "FontAwesome";
  color: #F4A700;
  font-size: 32px;
  line-height: 34px;
  content: "\f00e";
  font-weight: 400;
}
.info-product .pb-left-column .image-block .new-box,
.info-product .pb-left-column .image-block .sale-box {
  z-index: 29;
}
.info-product .pb-left-column .resetimg {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 40px;
}
.info-product .pb-left-column .resetimg span {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 40px;
}
.info-product .pb-left-column .resetimg span a {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
  font-size: 15px;
  height: 40px;
}
.info-product .pb-left-column .resetimg span a i {
  margin: 0px 6px 0px 0px;
  font-size: 15px;
}
.info-product .pb-left-column .resetimg span i:before {
  content: "\f01e";
}
.info-product .pb-right-column .attributes_group fieldset {
  padding: 0px 0px 0px 0px;
}
.info-product .pb-right-column .attributes_group fieldset label {
  vertical-align: top;
  display: block;
  margin: 0px 0px 0px 0px;
}

.info-product .pb-right-column .attributes_group .attribute_list {
  width: 100%;
  min-height: 34px;
}
.info-product .pb-right-column .attributes_group .attribute_list ul {
  clear: both
}
.info-product .pb-right-column .attributes_group .attribute_list ul li {
  float: left;
  margin-right: 10px
}
.info-product .pb-right-column .attributes_group .attribute_list input.attribute_radio {
  float: left;
  margin-left: 3px
}
.info-product .pb-right-column .attributes_group .attribute_list div.radio,
.info-product .pb-right-column .attributes_group .attribute_list div.radio span,
.info-product .pb-right-column .attributes_group .attribute_list div.radio input {
  float: none;
  display: inline-block
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list {
  list-style-type: none;
  min-height: 71px;
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list.colorDisplay {
  min-height: 91px;
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li {
  float: left;
  margin: 0px 3px 3px 0px;
  padding: 1px;
  border: 0;
  clear: none;
  width: 26px;
  height: 26px;
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list.colorDisplay li {
  height: 46px;
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li a.color_pick {
  display: block;
  height: 22px;
  width: 22px;
  cursor: pointer
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li a.color_pick img {
  display: block;
  height: 22px;
  width: 22px
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li.selected {
  border: 1px solid #4d4d4d
}
.info-product .pb-right-column .our_price_display {
  font-weight: 600;
  font-size: 29px;
  font-family: "Open Sans", sans-serif;
  color: #212121
}
@media(max-width:992px) {
  .info-product .pb-right-column .our_price_display {
    font-size: 26px;
    line-height: 28px
  }
 }
.info-product .pb-right-column .our_price_display .price {
  font-weight: 600;
  font-size: 29px;
  line-height: 32px;
  color: #212121;
}
@media(max-width:992px) {
  .info-product .pb-right-column .our_price_display .price {
    font-size: 26px;
    line-height: 28px;
  }
}
/* kombinacje wyłączone */
.info-product .pb-right-column li.disabled_combination, 
.info-product .pb-right-column li.disabled_combination > a {
  position: relative;
}
.info-product .pb-right-column li.disabled_combination > .disabled_output {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #9B9B9B;
  border: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  overflow: hidden;
  opacity: 0.5;
}
.info-product .pb-right-column li.disabled_combination:hover {
  border: 1px solid #9B9B9B !important;
  box-shadow: none !important;
}
.info-product .pb-right-column li.disabled_combination > a:hover {
  border: none !important;
  box-shadow: none !important;
}
.info-product .pb-right-column li.disabled_combination > .disabled_output:hover {
  border: none !important;
  box-shadow: none !important;
}
.info-product .pb-right-column .offer_number {
  margin: 0px 0px 0px 0px;
  padding: 9px 0px 9px 0px;
  width: 100%;
  height: 36px;
  color: #212121;
  font-size: 12px;
}
.info-product .pb-right-column {
  padding: 0px 15px 0px 0px;
}
.info-product .pb-right-column h1 {
  font-weight: 600;
  color: #212121;
  font-size: 20px;
}
.info-product .pb-right-column #price-and-timer {
  overflow: hidden;
}
.info-product .pb-right-column .new-product-price-timer {
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 50%;
  min-height: 1px;
  height: 70px;
}
.info-product .pb-right-column .price_block {
  display: flex;
}
.info-product .pb-right-column .price_block .new-product-price-timer {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  float: left;
  width: 50%;
  min-height: 1px;
  height: 70px;
}
.info-product .pb-right-column .price_block .old-price {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  height: 24px;
  text-decoration: line-through;
  color: #212121;
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
  font-size: 16px;
}
.info-product .pb-right-column .price_block .old-price.active {

}
.info-product .pb-right-column .price_block .reduction {
  display: inline-block;
  margin: 0px 0px 0px 5px;
  padding: 0px 10px 0px 10px;
  height: 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}
.info-product .pb-right-column .price_block .reduction.active {
  border-radius: 3px / 3px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY7BYbAUAAiwBFo8QZgoAAAAASUVORK5CYII=')
    repeat !important;
}
.info-product .pb-right-column .old-30-block {
  margin: 0px 0px 0px 0px; 
  padding: 0px 0px 0px 0px; 
  min-height: 18px;
  color: #212121; 
}
.info-product .pb-right-column .old-30-block .old-30-text {
  display: block; 
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px 5px 0px 0px;
}
.info-product .pb-right-column .old-30-block .old-30-price {
  display: block; 
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.info-product .pb-right-column .price-wrapper-code {
  display: block;
  visibility: hidden;
  margin: 0px 0px 0px 0px;
  width: 100%;
  min-height: 52px;
  font-size: 12px;
  font-weight: 600;
  color: #212121;
  text-transform: uppercase;
}
.info-product .pb-right-column .price-wrapper-code.active {
  visibility: visible;
}
.info-product .pb-right-column .price-wrapper-code > label {
  float: none;
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 8px 5px 8px 0px;
  font-size: 12px;
  font-weight: 600;
  color: #212121;
}
.info-product .pb-right-column .price-wrapper-code label .checker {
  float: left;
}
.info-product .pb-right-column .price-wrapper-code label .price-wrapper-code-input {
  margin: -3px 5px 0px 0px;
  vertical-align: middle;
}
.info-product .pb-right-column .price-wrapper-code label .price-wrapper-code-span {
  text-transform: none;
}
.info-product .pb-right-column .price-wrapper-code .price-wrapper-code-text, 
.info-product .pb-right-column .price-wrapper-code .price-wrapper-code-output {
  text-transform: none;
}
.info-product .pb-right-column .box-info-product {
  border: 0;
}
.info-product .pb-right-column .cena_od {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 18px;
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
  color: #212121;
}
.info-product .pb-right-column .our_price_display {
  display: block;
  margin-top: 5px;
  height: 26px;
  font-size: 25px;
  color: #F4A700;
  font-weight: 700;
  line-height: 25px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDCQqzU+sfwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/j//z8ABf4C/tzMWecAAAAASUVORK5CYII=');
}
.info-product .pb-right-column .new-product-price-timer .time_end_promotion {
  margin: 0px 0px 0px 0px;
  height: 70px;
}
.info-product .pb-right-column .new-product-price-timer .getting-ended {
  text-transform: uppercase;
  color: #32A332;
  letter-spacing: 2px;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 12px;
  height: 15px;
}
.info-product .pb-right-column .new-product-price-timer .getting-started {
  margin: 0px 0px 0px 0px;
  width: 202px;
  float: none;
  margin-left: 0;
  display: block;
  z-index: 0;
  height: 55px;
}
@media(max-width:460px) {
  .info-product .pb-right-column .new-product-price-timer .getting-started {
    width: 202px;
  }
}
.info-product .pb-right-column .new-product-shipment {
  display: inline-block;
  float: left;
  margin: 0px 0px 0px 0px !important;
  padding: 10px 0px 10px 0px !important;
  min-height: 80px;
  line-height: 18px;
}
@media(max-width:400px) {
  .info-product .pb-right-column .new-product-shipment {
    width: 100% !important;
  }
}
.info-product .pb-right-column .new-product-shipment .new-product-header {
  display: block;
  height: 24px;
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  color: #212121;
}
.info-product .pb-right-column .new-product-shipment .term_for_product {
  height: 20px;
}
.info-product .pb-right-column .new-product-shipment .cost_for_product {
  height: 40px;
}
.info-product .pb-right-column .product_payment_method_block1 {
  display: inline-block!important;
  float: left;
  margin: 18px 0px 0px 0px !important;
  padding: 0px 5px 0px 0px !important;
  width: 50%;
  height: 33px;
  font-size: 13px;
  clear: both;
}
.info-product .pb-right-column .product_payment_method_block2 {
  display: inline-block!important;
  float: left;
  margin: 10px 0px 9px 0px !important;
  padding: 0px 0px 0px 0px !important;
  width: 50%;
  height: 33px;
  font-size: 13px;
}
.info-product .pb-right-column .product_payment_method_block2 .product_payment_method_link {
  display: inline-block !important;
  margin: 0px 0px 0px 0px; 
  padding: 6px 12px 6px 12px;
  font-size: 13px;
}
.info-product .pb-right-column .product_payment_method_block2 .product_payment_method_link .icon-search {
  font-size: 13px;
}
.info-product .pb-right-column .product_payment_method_block2 .product_payment_method_link .icon-search:before {
  content: "\f002";
}
@media(max-width:768px) {
  .info-product .pb-right-column .product_payment_method_block1 {
    width: 75%;
  }
  .info-product .pb-right-column .product_payment_method_block2 {
    width: 25%;
  }
  .info-product .pb-right-column .product_payment_method_block2 .product_payment_method_link .icon-search {
    display: none;
  }
}
.info-product .pb-right-column .new-product-shipment .renouncement {
  text-align: center;
  display: block;
}
.info-product .pb-right-column .new-product-shipment span {
  color: #32A332;
  font-weight: 600;
  font-size: 15px;
}
.info-product .pb-right-column .new-product-shipment span:last-child {
  margin-right: 0;
}
.info-product .pb-right-column h1 {
  margin-top: 0;
  line-height: 22px;
  font-size: 20px;
  overflow: hidden;
}
.info-product .pb-right-column .attributes_group {
  display: inline-block;
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.info-product .pb-right-column .attribute_text {
  height: 24px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #212121;
}
.info-product .pb-right-column .attribute_text output {
  border-bottom: 2px solid #F4A700;
}
.info-product .pb-right-column .attributes_group .attribute_text span {
  color: #212121;
}
.info-product .pb-right-column .page-product-box {
  display: flex;
  float: left;
  text-align: center;
  margin: 0px 0px 4px 0px;
  width: 100%;
  clear: both;
}
.info-product .pb-right-column .pp_text_title {
  float: left;
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
  color: #212121;
  text-transform: uppercase;
  margin: 0px 0px 5px 0px;
  width: 100%;
  height: 18px;
  clear: both;
}
.info-product .pb-right-column #add_to_carta {
  padding: 0;
  background-repeat: no-repeat;
  background-image: url('/img/add_to_cart.png');
  background-color: #e1e1e1;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  clear: both;
}
.info-product .pb-right-column .add_to_carta_contener {
  position: relative;
}
.info-product .pb-right-column .info_carta {
  font-size: 13px;
  line-height: 13px;
  font-weight: 200;
  padding: 0 8px;
  text-transform: uppercase;
  position: absolute;
  top: 9px;
  z-index: 1;
  width: 265px
}
.info-product .pb-right-column #vwp_zamow {
  position: absolute;
  top: 4px;
  right: 7px;
  z-index: 1;
  margin: 0px 0px 0px 0px;
  padding: 6px 27px 6px 26px;
}
.info-product .pb-right-column #vwp_zamow span:after {
  font-family: "FontAwesome";
  content: "\f054";
  font-size: 12px;
  margin: 5px
}
.info-product .pb-right-column .info_carta {
  font-size: 13px;
  line-height: 13px;
  font-weight: 200;
  padding: 0 8px;
  text-transform: uppercase;
  position: absolute;
  top: 9px;
  z-index: 1;
  width: 265px;
}
.info-product .pb-right-column .new-product-count.header {
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
  color: #212121;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 60px;
  margin-right: 0;
  text-align: left;
  float: left;
  margin-top: 18px;
}
.info-product .pb-right-column .new-product-count.plus-minus {
  float: left;
  border: 1px solid #ccc;
  margin-top: 10px;
  width: 109px;
}
.info-product .pb-right-column .new-product-count.plus-minus.mobile {
  margin-bottom: 20px;
}
.info-product .pb-right-column .new-product-count.plus-minus input {
  border: 0;
  width: 27px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
}
.info-product .pb-right-column .new-product-count.plus-minus .minus,
.info-product .pb-right-column .new-product-count.plus-minus .plus {
  width: 35px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 15px !important;
  cursor: pointer;
  padding-top: 7px;
  height: 30px;
  user-select: none;
}
.info-product .pb-right-column .new-product-count.plus-minus .minus i,
.info-product .pb-right-column .new-product-count.plus-minus .plus i {
  font-size: 15px;
}
.info-product .pb-right-column .new-product-count.plus-minus .minus {
  border-right: 1px solid #dbdbdb;
}
.info-product .pb-right-column .new-product-count.plus-minus .plus {
  border-left: 1px solid #dbdbdb;
}
.info-product .pb-right-column .new-product-count.plus-minus .minus .icon-minus:before {
  content: "\f068";
}
.info-product .pb-right-column .new-product-count.plus-minus .plus .icon-plus:before {
  content: "\f067";
}
.info-product .pb-right-column .quantity_form {
  clear: both;
}
.info-product .pb-right-column .new-product-count.trash {
  margin-top: 10px;
  width: calc(100% - 184px);
  font-size: 18px !important;
}
.info-product .pb-right-column .new-product-count.trash button {
  font-size: 18px !important;
}
.info-product .pb-right-column .quantity_wanted_value {
  display: inline-block;
  padding-top: 0px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
}
.info-product .pb-right-column .trash {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDB4rn+FCkAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/iynBEABC8BnSLN56YAAAAASUVORK5CYII=');
  color: #FFFFFF;
  padding: 0px;
  line-height: 32px;
  text-align: center;
  font-weight: 600;
  margin-left: 15px;
  cursor: pointer;
  display: inline-block;
  border: 0;
}
@media (max-width: 340px) {
  .info-product .pb-right-column .trash {
    padding: 2px 0px 1px 0px !important;
	font-size: 16px !important;
  }
  .info-product .pb-right-column .trash button {
    font-size: 16px !important;
  }
  .info-product .pb-right-column .new-product-count.trash {
    font-size: 16px !important;
  }
  .info-product .pb-right-column .new-product-count.trash button {
    font-size: 16px !important;
  }
}
.info-product .pb-right-column .trash button {
  padding: 0px;
  margin-left: 0px;
  width: 100%;
}
.info-product .pb-right-column .flip-clock-wrapper {
  font: normal 11px "Helvetica Neue",Helvetica,sans-serif !important;
  font-style: normal !important;
  font-variant-caps: normal !important;
  font-variant-ligatures: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
  font-variant-alternates: normal !important;
  font-kerning: auto !important;
  font-optical-sizing: auto !important;
  font-feature-settings: normal !important;
  font-variation-settings: normal !important;
  font-variant-position: normal !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-size: 11px !important;
  line-height: normal !important;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  user-select: none !important;
}
.info-product .pb-right-column .flip-clock-wrapper * {
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
}
.info-product .pb-right-column .flip-clock-wrapper .flip {
  box-shadow: none;
  border: 1px solid #dbdbdb;
  border-radius: 0;
}
.info-product .pb-right-column .flip-clock-wrapper ul {
  height: 26px;
  width: 19px
}
.info-product .pb-right-column .flip-clock-wrapper ul li {
  line-height: 26px;
  background-color: #FFFFFF;
}
.info-product .pb-right-column .flip-clock-wrapper ul li a div div.inn {
  text-shadow: none;
  border-radius: 0;
  background-color: #FFFFFF;
  font-size: 16px;
  color: #494949;
}
.info-product .pb-right-column .flip-clock-divider {
  width: 6px;
  height: 38px;
}
.info-product .pb-right-column .flip-clock-divider.days {
  width: 0px;
  height: 38px;
}
.info-product .pb-right-column .flip-clock-divider .flip-clock-label {
  bottom: -8px;
  line-height: normal;
  font-weight: 400;
  font-size: 12px;
}
.info-product .pb-right-column .flip-clock-wrapper ul li a div.down {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.info-product .pb-right-column .flip-clock-dot {
  left: 2px;
  width: 2px;
  height: 2px;
}
.info-product .pb-right-column .flip-clock-dot.top {
  top: 12px;
}
.info-product .pb-right-column .flip-clock-dot.bottom {
  bottom: 17px;
}
.info-product .pb-right-column .flip-clock-divider.days .flip-clock-label {
  right: -31px;
}
.info-product .pb-right-column .flip-clock-divider.hours .flip-clock-label {
  right: -41px;
}
.info-product .pb-right-column .flip-clock-divider.minutes .flip-clock-label {
  right: -36px;
}
.info-product .pb-right-column .flip-clock-divider.seconds .flip-clock-label {
  right: -41px;
}
.info-product .pb-right-column .attributes_group .attributes_right {
  margin-bottom: 5px;
}
.info-product .pb-right-column .attributes_group .attribute_list {
  display: block;
  padding: 0px;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_select {
  display: none;
}
.info-product .pb-right-column .attributes_group .attribute_list ul li {
  margin-right: 3px;
}
.info-product .pb-right-column .attributes_group .attribute_list ul li.active a, 
.info-product .pb-right-column .attributes_group .attribute_list ul li.selected a {
  cursor: default !important;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list {
  display: inline-block;
  margin: 0px;
  padding-left: 0px;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li {
  display: inline-block;
  float: left;
  margin: 0px 5px 5px 0px;
  border: 1px solid #212121;
  text-align: center;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li div,
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li input {
  display: none;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li label {
  display: block;
  padding: 3px;
  height: 32px;
  line-height: 32px;
  min-width: 50px;
  padding: 0px 10px;
  color: #212121;
  text-decoration: none;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li label:hover {
  color: #212121;
}
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li.selected, 
.info-product .pb-right-column .attributes_group .attribute_list .attribute_radio_list li:hover {
  border: 1px solid #F4A700;
  box-shadow: inset 0px 0px 0px 1px #F4A700;
}
.info-product .pb-right-column .new-product-header {
  font-size: 13px;
  font-weight: 600;
  color: #212121;
  margin-bottom: 0;
  min-height: 24px;
  clear: both;
}

/* kombinacja typu kolor */
.info-product .pb-right-column .attribute_list .color_to_pick_list {
  min-height: 71px !important;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list.colorDisplay {
  min-height: 91px !important;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li {
  float: left;
  margin: 0px 5px 5px 0px !important;
  border: 1px solid #fff !important;
  padding: 0px !important;
  width: 66px !important;
  height: 66px !important;
  font-size: 8px;
  clear: none;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list.colorDisplay li {
  height: 86px !important;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li.selected {
  border: 1px solid #F4A700 !important;
  box-shadow:inset 0px 0px 0px 1px #F4A700;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li:not(.disabled_combination):hover {
  border: 1px solid #F4A700 !important;
  box-shadow:inset 0px 0px 0px 1px #F4A700;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li a {
  display: inline-block;
  margin: 3px;
  border: 0px solid #D6D4D4 !important;
  width: 58px !important;
  height: 58px !important;
  vertical-align: top;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li a:hover {
  border: 0px solid #D6D4D4 !important;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li a img {
  width: 58px !important;
  height: 58px !important;
  height: auto;
  vertical-align: top;
}
.info-product .pb-right-column .attribute_list .color_to_pick_list li .color_display {
  display: none;
  margin: 5px 0px 0px 0px;
  font-size: 13px;
  color: #212121;
  text-align: center;
}
/* kombinacja typu kolor */

/* wymiar, powierzchnia spania, powierzchnia spania i (pojemnik i/lub stelaż), rozmiar */
.info-product .pb-right-column .variants-attribute-select {
  margin: 0px 0px 0px 0px;
  width: 100%;
}
.info-product .pb-right-column .variants-attribute-select li {
  display: inline-block;
  text-align: center;
  margin: 0px 5px 5px 0px;
  height: 34px;
}
.info-product .pb-right-column .variants-attribute-select li a {
  display: inline-block;
  border: 1px solid #212121;
  padding: 3px;
  height: 34px;
  line-height: 34px;
  min-width: 50px;
  padding: 0 10px;
  color: #212121;
  text-decoration: none;
  position: relative;
}
.info-product .pb-right-column .variants-attribute-select li:hover a {
  border: 0;
}
.info-product .pb-right-column .variants-attribute-select li a:hover {
  color: #212121;
}
.info-product .pb-right-column .variants-attribute-select li:not(.active) a:hover, 
.info-product .pb-right-column .variants-attribute-select li.active a {
  border: 1px solid #F4A700;
  box-shadow: inset 0px 0px 0px 1px #F4A700;
}
.info-product .pb-right-column .variants-attribute-select li.active a {
  cursor: default !important;
}
/* wymiar, powierzchnia spania, powierzchnia spania i (pojemnik i/lub stelaż), rozmiar */

/* odwrotna strona, zdjęcie */
.info-product .pb-right-column .variants-attribute-color1 {
  width: 100%;
}
.info-product .pb-right-column .variants-attribute-color1 img {
  height: 58px;
  width: 58px;
}
.info-product .pb-right-column .variants-attribute-color1 li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
}
.info-product .pb-right-column .variants-attribute-color1 li.lustro, 
.info-product .pb-right-column .variants-attribute-color1 li.nieuniwersalny {
  width: 100%;
}
.info-product .pb-right-column .variants-attribute-color1 li a {
  display: inline-block;
  padding: 3px;
  border: 1px solid #FFFFFF;
  width: 66px;
  height: 66px;
}
.info-product .pb-right-column .variants-attribute-color1 li.active a,
.info-product .pb-right-column .variants-attribute-color1 li a:hover,
.info-product .pb-right-column .variants-attribute-color1 li.active p,
.info-product .pb-right-column .variants-attribute-color1 li p:hover {
  border: 1px solid #f4a700;
  box-shadow:inset 0px 0px 0px 1px #f4a700;
}
.info-product .pb-right-column .variants-attribute-color1 li.active a {
  cursor: default !important;
}
.info-product .pb-right-column .variants-attribute-color1 li p {
  display: inline-block;
  margin: 0px;
  border: 1px solid #ccc;
  padding: 3px;
  height: 64px;
  width: 131px;
  cursor: pointer;
}
.info-product .pb-right-column .variants-attribute-color1 li p .lustro {
  margin-left: 3px;
  transform: scaleX(-1);
}
.info-product .pb-right-column .variants-attribute-color1 li span {
  display: inline-block;
  max-height: 66px;
  max-width: calc(100% - 136px);
  padding: 0px 0px 0px 5px;
}
/* zdjęcie, odwrotna strona */

/* zdjęcie i wymiar, zdjęcie i środek szafy */
.info-product .pb-right-column .variants-attribute-color2 li {
  padding: 2px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
.info-product .pb-right-column .variants-attribute-color2 li.active a {
  border: 2px solid #f4a700;
  box-shadow: none;
  cursor: default !important;
}
.info-product .pb-right-column .variants-attribute-color2 li a {
  border: 2px solid #fff;
  display: inline-block;
  padding: 5px;
  color: #212121;
}
.info-product .pb-right-column .variants-attribute-color2 li:not(.active) a:hover {
  border: 2px solid #f4a700;
  box-shadow: none;
}
.info-product .pb-right-column .variants-attribute-color2 li p {
  margin-bottom: 0;
  font-size: 12px;
}
.info-product .pb-right-column .variants-attribute-color2 img {
  border: 1px solid #ccc;
  padding: 3px;
  width: 66px;
}
/* zdjęcie i wymiar, zdjęcie i środek szafy */


/* opis atrybutu w oknie modalnym */
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li a {
  position: relative;
}
.info-product .pb-right-column .attributes_group .attribute_list .color_to_pick_list li a > span {
  display: inline-block;
  margin: 12px 0px 12px 0px;
  padding: 0px 0px;
  height: 34px;
  line-height: 11px;
  min-width: 50px;
  color: #212121;
  font-size: 13px;
  text-decoration: none;
  text-align: center;
}
.info-product .pb-right-column .atrybut_opis .info {
  display: block;
  float: right;
  position: absolute;
  top: -8px;
  right: -3px;
  z-index: 1;
  padding: 0px 0px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #7E6F6F;
  background-color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid #C0BCBC;
}
.info-product .pb-right-column .color_to_pick_list.atrybut_opis .info {
  display: block;
  float: right;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  margin: 3px 5px 3px 0px;
  padding: 0px 0px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #7E6F6F;
  background-color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50%;
  border: 1px solid #C0BCBC;
}
.info-product .pb-right-column .atrybut_opis .info span {
  display: block;
  position: absolute;
  top: -8px;
  width: 100%;
  height: 100%;
}
.info-product .pb-right-column .color_to_pick_list.atrybut_opis .info span {
  display: block;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}
.info-product .pb-right-column .atrybut_opis .tlo {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #212121;
  z-index: 45;
  opacity: 0.7;
}
.info-product .pb-right-column .atrybut_opis .opis {
  display: none;
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 46;
  height: 100%;
  max-height: 600px;
  width: 100%;
  max-width: 800px;
  margin: calc(50% - 300px) calc(50% - 400px);
  padding: 10px;
  color: #212121;
  background-color: #FFFFFF;
}
.info-product .pb-right-column .atrybut_opis .opis::-webkit-scrollbar-track, 
#vwp_fancybox_opis_tkanina::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.info-product .pb-right-column .atrybut_opis .opis::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
.info-product .pb-right-column .atrybut_opis .opis::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #6f6f6f;
}
.info-product .pb-right-column .atrybut_opis .opis > i {
  display: block;
  float: right;
  font-size: 18px !important;
  margin: -8px -8px 0px 0px;
  width: 24px;
  height: 24px;
  background: url('/img/close2.png') no-repeat;
  background-size: 24px;
  cursor: pointer;
}
.info-product .pb-right-column .atrybut_opis .opis > img {
  margin: 30px auto 0px auto;
  max-width: 600px;
}
.info-product .pb-right-column .atrybut_opis .opis > .opis_h {
  display: block;
  overflow: hidden;
  margin: 10px auto 10px auto;
  padding: 0px 0px 0px 0px;
  width: 100%;
  color: #212121;
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
}
.info-product .pb-right-column .atrybut_opis .opis output * {
  font-size: 13px;
  text-align: left;
}
.info-product .pb-right-column .atrybut_opis .opis output strong {
  font-weight: bold;
}
.info-product .pb-right-column .atrybut_opis .opis output ul {
  display: block;
  list-style-type: disc;
  margin: 1em 0em 1em 0em;
}
/* opis atrybutu w oknie modalnym */
.info-product .vx_product_name_1 {
  display: none;
}
.info-product .vx_product_name_2 {
  display: block;
}
@media (max-width: 768px) {
  .info-product .vx_product_name_1 {
  	display: block;
  }
  .info-product .vx_product_name_2 {
  	display: none;
  }
}
@media (max-width: 1200px){
  .info-product .pb-right-column #end-model {
    margin-top: 0px;
  }
  .info-product .pb-right-column #see-similar-furniture {
    margin-bottom: 20px;
  }
}
@media (max-width: 850px) {
  .info-product .pb-right-column .new-product-price-timer .time_end_promotion {
    font-size: 9px;
  }
}
@media (max-width: 768px){
  .info-product .vx_product_name_1 {
  	font-weight: 600;
  	color: #212121;
	line-height: 22px;
  	font-size: 20px;
  }
  .info-product .pb-left-column {
  	margin-bottom: 10px;
  }
  .info-product .pb-right-column {
    padding-left: 15px;
  }
  .info-product .pb-right-column .new-product-price-timer .time_end_promotion {
    font-size: 12px;
  }
}
@media(max-width:568px) {
  .info-product .pb-right-column #add_to_carta {
    height: 70px;
	background-image: url('/img/add_to_cart_small.png');
  }
  .info-product .pb-right-column .info_carta {
    display: none;
  }
}
@media (max-width: 550px){
  .info-product .pb-right-column .new-product-price-timer .time_end_promotion {
    font-size: 9px;
  }
}
@media (max-width: 480px){
  .info-product .pb-right-column #price {
	margin-top: 0 !important;
  }
  .info-product .pb-right-column .new-product-price-timer {
    height: 76px !important;
  }
  .info-product .pb-right-column .shipment {
    height: 76px !important;
  }
  .info-product .pb-right-column .new-product-price-timer .time_end_promotion {
    margin-bottom: 5px;
  }
  .info-product .pb-right-column .new-product-price-timer .getting-started {
    margin: 0px 0px 0px 0px;
  }
  .info-product .pb-right-column .new-product-price-timer .time_end_promotion {
    font-size: 12px;
  }
  #product .table_data_sheet {
    margin-top: 0;
  }
  .info-product .pb-right-column .reduction-old-price .reduction {
    margin: 0px 0px 0px 0px;
  }
}
@media (max-width: 431px){
  .info-product .pb-right-column .product_block_price {
    width: 45% !important;
  }
  .info-product .pb-right-column .product_block_promotion {
    width: 55% !important;
  }
}
@media (max-width: 400px){
  .info-product .pb-right-column .price_block {
    display: grid;
    min-height: 160px !important;
  }
  .info-product .pb-right-column .new-product-price-timer {
    width: 100% !important;
  }
  .info-product .pb-right-column .shipment {
    width: 100% !important;
  }
}
.info-product .pb-left-column .image-block .tkanina .tkanina_nazwa_blok {
  display: block; 
  height: 333px; 
  width: 100%; 
}
.info-product .pb-left-column .image-block .tkanina .tkanina_nazwa {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  height: 31px;
  padding: 6px 8px;
  font-size: 15px;
  color: #FFFFFF;
  background-color: #212121; 
}
#product_preview_form .info-product .pb-left-column .image-block .tkanina .tkanina_nazwa {
  padding: 4px 8px;
}
.info-product .pb-left-column .image-block .tkanina .tkanina_tlo {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 31px;
  padding: 8px;
  font-size: 15px;
  color: #FFFFFF;
  background-color: gray;
  opacity: 0.2;
}
.info-product .pb-left-column .image-block .tkanina .tkanina_napis {
  display: block;
  position: absolute;
  top: 0px;
  text-align: center;
}
.info-product .pb-left-column .image-block .bigpic.lustro {
  margin-left: 3px;
  transform: scaleX(-1);
}
.info-product .pb-left-column .view_scroll_left {
  display: block;
  overflow: hidden;
  float: left;
  margin: 0px 0px 0px 0px;
  border: none;
  padding: 34px 1px 34px 0px;
  width: 40px;
  height: 88px;
  background-color: #FFFFFF;
  line-height: 0px;
  text-decoration: none;
}
.info-product .pb-left-column .view_scroll_left:before {
  font-family: "FontAwesome";
  content: "\f137";
  padding: 0px 2px 0px 0px;
  color: #F4A700;
  font-size: 22px;
  line-height: 22px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.info-product .pb-left-column .view_scroll_right {
  display: block;
  overflow: hidden;
  float: left;
  margin: 0px 0px 0px 0px;
  border: none;
  padding: 34px 0px 34px 1px;
  width: 40px;
  height: 88px;
  background-color: #FFFFFF;
  line-height: 0px;
  text-decoration: none;
}
.info-product .pb-left-column .view_scroll_right:before {
  font-family: "FontAwesome";
  content: "\f138";
  padding: 0px 0px 0px 2px;
  color: #F4A700;
  font-size: 22px;
  line-height: 22px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.info-product .pb-left-column .views_block {
  margin-top: 10px;
  height: 88px;
}
body.mobile .info-product .pb-left-column .views_block {
  height: 128px;
}
@media(max-width:768px) {
  .info-product .pb-left-column .views_block {
    margin: 10px auto 0 auto;
  }
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame {
  list-style-type: none;
  margin-bottom: 0px;
  padding-left: 0px;
  overflow: hidden;
  height: 88px;
}
body.mobile .info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame {
  height: 128px;
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li {
  float: left;
  margin: 0px 5px 0px 0px;
  border: none;
  padding: 0px 0px 0px 0px;
  width: 88px;
  height: 88px;
  cursor: pointer;
  line-height: 0;
  box-sizing: border-box;
}
body.mobile .info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li {
  width: 128px;
  height: 128px;
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a {
  display: block;
  border: 1px solid #FFFFFF;
  padding: 0px;
  width: 88px;
  height: 88px;
  transition: all .3s ease;
  box-sizing: border-box;
}
body.mobile .info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a {
  width: 128px;
  height: 128px;
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a img {
  border: 0px;
  padding: 3px;
  width: 86px;
  height: 86px;
  box-sizing: border-box
}
body.mobile .info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a img {
  width: 126px;
  height: 126px;
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a:hover {
  border: 1px solid #F4A700;
}
.info-product .pb-left-column .views_block .thumbs_list .thumbs_list_frame li a.shown {
  border: 1px solid #F4A700;
}
.info-product .boxQuantityDiscount {
  display: none!important;
}
/* Nowy strona produktu - Pierwsza sekcja - koniec - Rudnicki */

#product_preview_form {
  display: none;
}
#product_preview_form.cloud {
  display: block;
}
#product_preview_form .background {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
}
#product_preview_form > .panel {
  position: fixed !important;
  top: 0px;
  left: 0px;
  z-index: 31;
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
#product_preview_form .panel-heading {
  margin: 0px 0px 0px 0px;
  padding: 15px 18px 0px 18px;
  height: 43px;
  background-color: #FFFFFF;
  clear: both;
}
@media (max-width: 768px) {
  #product_preview_form .panel-heading {
    margin: 52px 0px 0px 0px;
  }
}
#product_preview_form .panel-heading h2 {
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: calc(100% - 24px);
  font-size: 21px;
  font-weight: 600;
  line-height: 1.1;
}
#product_preview_form .panel-heading .close {
  display: block;
  float: right;
  font-size: 18px !important;
  margin: 0px 0px 0px 0px;
  width: 24px;
  height: 24px;
  background: url('/img/close2.png') no-repeat;
  background-size: 24px;
}
#product_preview_form .panel-heading .close:hover {
  opacity: 0.7;
}
#product_preview_form .panel-body {
  overflow: visible;
  margin: 0px 0px 0px 0px;
  padding: 0px 18px 0px 18px;
  background-color: #FFFFFF;
}
#product_preview_form a[disabled]:not(.pbtn) {
  color: #D6D4D4 !important;
  cursor: default !important;
}
#product_preview_form a:not(.pbtn):hover[disabled] {
  opacity: 1 !important;
  cursor: default !important;
}
#product_preview_form .panel-footer {
  margin: 0px 0px 0px 0px;
  border: none;
  padding: 5px 18px 15px 18px;
  height: 61px;
  background-color: #FFFFFF;
}
#product_preview_form .panel-footer #combination_modal_confirm {
  float: right;
  margin: 0px 0px 0px 0px;
  height: 41px;
  width: 100%;
}
#product_preview_form .panel-footer #combination_modal_confirm > a {
  display: inline-block !important; 
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 41px;
}
#product_preview_form .panel-footer #combination_modal_confirm > a > span {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 10px 40px 10px 40px;
  text-transform: uppercase;
}
#product_preview_form .panel-footer #combination_modal_confirm > span {
  display: inline-block !important; 
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 41px;
}
#product_preview_form .panel-footer #combination_modal_confirm > span > button {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  padding: 10px 40px 10px 40px;
  font-size: 13px;
  text-transform: uppercase;
}
#product_preview_form .panel-footer .combination_modal_confirm > span > button {
  font-size: 13px;
}
