/*countdown */

/* Get the bourbon mixin from http://bourbon.io */
/* Reset */

#getting-started {
  margin-left: 35px;
}

.flip-clock-wrapper * {
  box-sizing: border-box;
  backface-visibility: hidden;
}

.flip-clock-wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: #ccc;
}

.flip-clock-wrapper a:hover {
  color: #fff;
}

.flip-clock-wrapper ul {
  list-style: none;
}

.flip-clock-wrapper.clearfix:before,
.flip-clock-wrapper.clearfix:after {
  content: " ";
  display: table;
}

.flip-clock-wrapper.clearfix:after {
  clear: both;
}

.flip-clock-wrapper.clearfix {
  zoom: 1;
}

/* Main */
.flip-clock-wrapper {
  font-size: 11px;
  line-height: 20px;
  user-select: none;
}

.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 36px !important;
}

.flip-clock-meridium a {
  color: #313333;
}

.flip-clock-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}

.flip-clock-wrapper:before,
.flip-clock-wrapper:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}
.flip-clock-wrapper:after {
  clear: both;
}

/* Skeleton */
.flip-clock-wrapper ul {
  position: relative;
  float: left;
  margin: 2px;
  width: 25px;
  height: 39px;
  font-size: 80px;
  font-weight: bold;
  line-height: 87px;
  border-radius: 6px;
  background: #545454;
}

.flip-clock-wrapper ul li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 41px;
  text-decoration: none !important;
}

.flip-clock-wrapper ul li:first-child {
  z-index: 2;
}

.flip-clock-wrapper ul li a {
  display: block;
  height: 100%;
  perspective: 200px;
  margin: 0 !important;
  overflow: visible !important;
  cursor: default !important;
}

.flip-clock-wrapper ul li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  font-size: 80px;
  overflow: hidden;
  outline: 1px solid transparent;
}

.flip-clock-wrapper ul li a div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.flip-clock-wrapper ul li a div.up {
  transform-origin: 50% 100%;
  top: 0;
}

.flip-clock-wrapper ul li a div.up:after {
  content: "";
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
}

.flip-clock-wrapper ul li a div.down {
  transform-origin: 50% 0;
  bottom: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.flip-clock-wrapper ul li a div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #ccc;
  text-shadow: 0 1px 2px #000;
  text-align: center;
  background-color: #212121;
  border-radius: 6px;
  font-size: 30px;
}

.flip-clock-wrapper ul li a div.up div.inn {
  top: 0;
}

.flip-clock-wrapper ul li a div.down div.inn {
  bottom: 0;
}

/* PLAY */
.flip-clock-wrapper ul.play li.flip-clock-before {
  z-index: 3;
}

.flip-clock-wrapper .flip {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}

.flip-clock-wrapper ul.play li.flip-clock-active {
  animation: asd 0.5s 0.5s linear both;
  z-index: 5;
}

.flip-clock-divider {
  float: left;
  display: inline-block;
  position: relative;
  width: 11px;
  height: 54px;
}

.flip-clock-divider:first-child {
  width: 0;
}

.flip-clock-dot {
  display: block;
  background: #323434;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 3px;
}

.flip-clock-divider .flip-clock-label {
  position: absolute;
  bottom: -5px;
  right: -44px;
  color: black;
  text-shadow: none;
  color: #212121;
}

.flip-clock-divider.minutes .flip-clock-label {
  right: -42px;
}

.flip-clock-divider.seconds .flip-clock-label {
  right: -48px;
}

.flip-clock-divider.days .flip-clock-label {
  right: -39px;
}
.flip-clock-divider.hours .flip-clock-label {
  right: -48px;
}
.flip-clock-dot.top {
  top: 13px;
}

.flip-clock-dot.bottom {
  bottom: 23px;
}
@keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}

.flip-clock-wrapper ul.play li.flip-clock-active .down {
  z-index: 2;
  animation: turn 0.5s 0.5s linear both;
}

@keyframes turn {
  0% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.flip-clock-wrapper ul.play li.flip-clock-before .up {
  z-index: 2;
  animation: turn2 0.5s linear both;
}

@keyframes turn2 {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

.flip-clock-wrapper ul li.flip-clock-active {
  z-index: 3;
}

/* SHADOW */
.flip-clock-wrapper ul.play li.flip-clock-before .up .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
  animation: show 0.5s linear both;
}

.flip-clock-wrapper ul.play li.flip-clock-active .up .shadow {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
  animation: hide 0.5s 0.3s linear both;
}

/*DOWN*/
.flip-clock-wrapper ul.play li.flip-clock-before .down .shadow {
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
  animation: show 0.5s linear both;
}

.flip-clock-wrapper ul.play li.flip-clock-active .down .shadow {
  background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
  animation: hide 0.5s 0.2s linear both;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* end countdown */

@media (max-width: 1200px) {
  .quantity_wanted_p {
    text-align: center;
  }
  #pr_box_info_product .quantity_wanted_p .btn {
    float: none;
    width: auto;
  }
}
@media (max-width: 1166px) {
  .flip-clock-wrapper ul {
    width: 22px;
    height: 35px;
  }
  .flip-clock-wrapper ul li {
    line-height: 38px;
  }
  .flip-clock-wrapper ul li a div div.inn {
    font-size: 20px;
  }
  .flip-clock-divider.days .flip-clock-label {
    right: -33px;
  }
  .flip-clock-divider.hours .flip-clock-label {
    right: -41px;
  }
  .flip-clock-divider.minutes .flip-clock-label {
    right: -37px;
  }

  .flip-clock-divider.seconds .flip-clock-label {
    right: -43px;
  }
  .flip-clock-divider {
    height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 870px) {
  .flip-clock-wrapper ul {
    width: 22px;
    height: 28px;
    margin: 1px;
  }
  .flip-clock-wrapper ul li {
    line-height: 30px;
  }
  .flip-clock-wrapper ul li a div div.inn {
    font-size: 16px;
  }
  .flip-clock-divider {
    width: 4px;
  }
  .flip-clock-dot {
    width: 0;
    height: 0;
  }
  .flip-clock-divider .flip-clock-label {
    bottom: 0;
  }
  .flip-clock-divider.days .flip-clock-label {
    right: -31px;
  }
  .flip-clock-divider.hours .flip-clock-label {
    right: -40px;
  }
  .flip-clock-divider.minutes .flip-clock-label {
    right: -37px;
  }

  .flip-clock-divider.seconds .flip-clock-label {
    right: -41px;
  }
  .flip-clock-divider {
    height: 50px;
  }
}
@media (max-width: 768px) {
  .pb-left-column {
    height: auto !important;
  }
  #getting-started {
    margin-left: 25%;
  }
}
@media (max-width: 568px) {
  #getting-started {
    margin-left: 10px;
    float: right;
  }
}
@media (max-width: 450px) {
  .flip-clock-wrapper ul {
    width: 22px;
    height: 28px;
  }
  .flip-clock-wrapper ul li {
    line-height: 30px;
  }
  .flip-clock-wrapper ul li a div div.inn {
    font-size: 16px;
  }
  .flip-clock-divider {
    width: 9px;
  }
  .flip-clock-dot {
    width: 3px;
    height: 3px;
  }
  .flip-clock-divider .flip-clock-label {
    bottom: 0;
  }
  .flip-clock-divider.days .flip-clock-label {
    right: -33px;
  }
  .flip-clock-divider.hours .flip-clock-label {
    right: -41px;
  }
  .flip-clock-divider.minutes .flip-clock-label {
    right: -37px;
  }

  .flip-clock-divider.seconds .flip-clock-label {
    right: -43px;
  }
  .flip-clock-divider {
    height: 45px;
  }
  .flip-clock-dot.bottom {
    bottom: 24px;
  }
  .flip-clock-dot.top {
    top: 12px;
  }
}
@media (max-width: 420px) {
  .flip-clock-wrapper ul {
    width: 20px;
    height: 25px;
    margin: 1px;
  }
  .flip-clock-wrapper ul li {
    line-height: 26px;
  }
  .flip-clock-wrapper ul li a div div.inn {
    font-size: 13px;
  }
  .flip-clock-divider {
    width: 8px;
  }
  .flip-clock-dot {
    width: 3px;
    height: 3px;
  }
  .flip-clock-divider .flip-clock-label {
    bottom: 0;
  }
  .flip-clock-divider.days .flip-clock-label {
    right: -28px;
  }
  .flip-clock-divider.hours .flip-clock-label {
    right: -36px;
  }
  .flip-clock-divider.minutes .flip-clock-label {
    right: -33px;
  }

  .flip-clock-divider.seconds .flip-clock-label {
    right: -39px;
  }
  .flip-clock-divider {
    height: 45px;
  }
  .flip-clock-dot.bottom {
    bottom: 26px;
  }
  .flip-clock-dot.top {
    top: 11px;
  }
}
/** End New box info product **/
